import React from 'react';

const IconTopMate = () => (
  <svg
    width="1024"
    height="1024"
    viewBox="0 0 1024 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="512" cy="512" r="506.5" stroke="black" strokeWidth="22" />
    <mask id="path-2-inside-1_10_14" fill="white">
      <path d="M775.552 467.527C767.229 406.669 738.062 350.579 693.021 308.814C647.979 267.05 589.85 242.195 528.537 238.485C467.225 234.775 406.522 252.438 356.773 288.467C307.024 324.495 271.306 376.658 255.706 436.069C240.105 495.48 245.587 558.462 271.218 614.284C296.848 670.106 341.041 715.314 396.267 742.205C451.492 769.096 514.334 776.007 574.083 761.759C633.833 747.512 686.794 712.987 723.942 664.069L512.5 503.5L775.552 467.527Z" />
    </mask>
    <path
      d="M775.552 467.527C767.229 406.669 738.062 350.579 693.021 308.814C647.979 267.05 589.85 242.195 528.537 238.485C467.225 234.775 406.522 252.438 356.773 288.467C307.024 324.495 271.306 376.658 255.706 436.069C240.105 495.48 245.587 558.462 271.218 614.284C296.848 670.106 341.041 715.314 396.267 742.205C451.492 769.096 514.334 776.007 574.083 761.759C633.833 747.512 686.794 712.987 723.942 664.069L512.5 503.5L775.552 467.527Z"
      stroke="black"
      strokeWidth="22"
      mask="url(#path-2-inside-1_10_14)"
    />
  </svg>
);

export default IconTopMate;
